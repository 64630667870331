// Update

.nav-tabs,
.topbar ul,
ul.contact-info,
ul.menu {
  padding-left: 0;
  list-style: none !important;
}

.node--view-mode-full {
  p {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

body {
  div.body-page {
    background-color: #fff;
  }
}
